<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card outlined class="primary--border">
          <v-card-title style="border-bottom:1px solid #ccc" class="title">
            SMS Templates
            <v-spacer></v-spacer>
            <add-button
              permission="grade-create"
              @action="
                (form.dialog = true), $refs.form ? $refs.form.reset() : ''
              "
              >New Template</add-button
            >
          </v-card-title>
          <br />
          <v-data-table
            :headers="headers"
            hide-default-footer
            :items="form.items.data"
            :options.sync="pagination"
            sort-by="id"
            :server-items-length="form.items.meta.total"
            :loading="form.loading"
          >
            <template v-slot:item="{ index, item }">
              <tr>
                <td>{{ index + form.items.meta.from }}</td>
                <td class="text-xs-left">{{ item.title }}</td>
                <td class="text-xs-left">{{ item.message }}</td>
                <td class="text-right">
                  <edit-button
                    permission="grade-update"
                    @agree="form.edit(item)"
                  />
                  <delete-button
                    permission="grade-delete"
                    @agree="form.delete(item.id)"
                  />
                </td>
              </tr>
            </template>
            <v-alert
              slot="no-results"
              :value="true"
              color="error"
              icon="warning"
            >
              Your search for "{{ search }}" found no results.
            </v-alert>
          </v-data-table>
        </v-card>
      </v-flex>
      <v-dialog v-model="form.dialog" persistent max-width="600px">
        <v-card>
          <v-card-title class="primary white--text">
            <span class="title">Add/Update</span>
          </v-card-title>
          <v-card-text class="pb-1 pt-5">
            <v-form
              ref="form"
              @submit.prevent="save"
              @keydown.native="form.errors.clear($event.target.name)"
              @keyup.enter="save"
              v-model="valid"
              :lazy-validation="lazy"
            >
              <v-text-field
                outlined
                dense
                autocomplete="off"
                label="Title*"
                required
                class="pa-0"
                v-model="form.title"
                name="title"
                :error-messages="form.errors.get('title')"
                :rules="[(v) => !!v || 'Title Field is required']"
              />
              <v-textarea
                outlined
                dense
                autocomplete="off"
                label="Message*"
                required
                class="pa-0"
                v-model="form.message"
                name="rank"
                :rows="4"
                :error-messages="form.errors.get('message')"
                :rules="[(v) => !!v || 'Message Field is required']"
              />
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="warning"
              text
              @click="(form.dialog = false), form.reset()"
              >Close</v-btn
            >
            <v-btn color="success" text @click="validate">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import Form from "@/library/Form";
import Mixins from "@/library/Mixins";

export default {
  mixins: [Mixins],
  data: () => ({
    valid: true,
    lazy: false,
    isLoading: false,
    co_ordinator: [],
    model: null,
    entries: [],
    form: new Form(
      {
        title: "",
        message: "",
      },
      "/api/sms/template"
    ),
    search: "",
    searchCo: "",
    teacherList: [],
    pagination: {},
    headers: [
      { text: "#", align: "left", value: "id", width: 50, sortable: false },
      { text: "Title", align: "left", value: "title" },
      { text: "Message", align: "left", value: "message" },
      { text: "Action", align: "right", sortable: false, width: 200 },
    ],
  }),

  computed: {
    ...mapState(["batch"]),
  },

  watch: {
    pagination: function() {
      this.get();
    },
    batch: function(value) {
      this.get();
    },
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.save();
      }
    },
    get(params) {
      let query = [null, undefined].includes(params)
        ? this.queryString()
        : params;
      this.form.get(null, query).then(({ data }) => {
        this.pagination.totalItems = data.meta.total;
      });
    },

    save() {
      this.form.batch_id = this.batch.id;
      if (this.form.coordinator_id && Object.keys(this.form.coordinator_id))
        this.form.coordinator_id = this.form.coordinator_id.id;

      this.form.store();
    },
  },
};
</script>
<style lang="scss" scoped></style>
